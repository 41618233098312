import React from 'react';

const test = () => {
    return (
        <div>
            test
        </div>
    );
}
 
export default test;
